import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Image, Button } from 'react-bootstrap';
import delPopIcon from '../../assets/del_pop_icon.svg';
import styles from './RefundModal.module.scss';

function RefundModal({ show, handleClose, onRefund, userDetails }) {
  return (
    <Modal show={show} onHide={handleClose} className="delPopContainer" centered>
      <Modal.Body>
        <Image className="delet_icon" src={delPopIcon} alt="delete pop Icon" />
        <p>Are you sure you want to refund {userDetails?.appointment_client_id?.name} ?</p>
        <div className="btn_container">
          <Button className={styles.resetbtn} onClick={handleClose}>
            <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 10C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12H15C15.2652 12 15.5196 11.8946 15.7071 11.7071C15.8946 11.5196 16 11.2652 16 11C16 10.7348 15.8946 10.4804 15.7071 10.2929C15.5196 10.1054 15.2652 10 15 10H7Z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11ZM20 11C20 12.1819 19.7672 13.3522 19.3149 14.4442C18.8626 15.5361 18.1997 16.5282 17.364 17.364C16.5282 18.1997 15.5361 18.8626 14.4442 19.3149C13.3522 19.7672 12.1819 20 11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 8.61305 2.94821 6.32387 4.63604 4.63604C6.32387 2.94821 8.61305 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11Z"
              />
            </svg>
            No
          </Button>
          <Button className={styles.deleteBtn} onClick={onRefund}>
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25 4.25L3.1875 19.25C3.23203 20.1167 3.8625 20.75 4.6875 20.75H13.3125C14.1408 20.75 14.7595 20.1167 14.8125 19.25L15.75 4.25"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M0.75 4.25H17.25H0.75Z" fill="black" />
              <path
                d="M0.75 4.25H17.25"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M6 4.25V2.375C5.99957 2.22715 6.02837 2.08066 6.08475 1.94397C6.14114 1.80729 6.22399 1.6831 6.32854 1.57854C6.43309 1.47399 6.55728 1.39114 6.69397 1.33476C6.83066 1.27838 6.97714 1.24957 7.125 1.25H10.875C11.0229 1.24957 11.1693 1.27838 11.306 1.33476C11.4427 1.39114 11.5669 1.47399 11.6715 1.57854C11.776 1.6831 11.8589 1.80729 11.9152 1.94397C11.9716 2.08066 12.0004 2.22715 12 2.375V4.25M9 7.25001V17.75M5.625 7.25001L6 17.75M12.375 7.25001L12 17.75"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Yes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

RefundModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onRefund: PropTypes.func.isRequired,
  userDetails: PropTypes.func.isRequired,
};

export default RefundModal;
